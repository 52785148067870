import React from "react";
import Layout from "../../components/layout/Layout";
import ForgotPasswordContent from "../../components/ForgotPasswordContent";
import { useLocation } from "@reach/router";
import { graphql, HeadFC, Script } from "gatsby";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";

const ForgotPassword = () => {

	return (
		<Layout>
			<ForgotPasswordContent />
		</Layout>
	);

};

export const query = graphql`
	query {
		wp {
			isSearchEngineEnabled
		}
	}
`;

export const Head: HeadFC<any> = ({ data }) => {
	const location = useLocation();
	const isSearchEnabled = data.wp.isSearchEngineEnabled;

	return (
		<>
			<title>Forgot Password</title>
			<meta name="robots" content={!isSearchEnabled ? "noindex, nofollow" : "index, follow"} />
			<link rel="canonical" href={`${location.origin}${location.pathname}`}></link>
		</>
	);
};

export default ForgotPassword;